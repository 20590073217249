var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticStyle: { "margin-top": "5px" } }, [
        _c(
          "div",
          { staticClass: "button-click-left" },
          [
            _c(
              "el-select",
              {
                staticClass: "button-left",
                attrs: { placeholder: "Trạng thái", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.statusAppSend,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "statusAppSend", $$v)
                  },
                  expression: "dataSearch.statusAppSend",
                },
              },
              _vm._l(_vm.statusAppSend, function (item) {
                return _c("el-option", {
                  key: item.key,
                  attrs: { value: item.key, label: item.value },
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "unlink-panels": "",
                    "start-placeholder": "Ngày bắt đầu",
                    "end-placeholder": "Ngày kết thúc",
                    "value-format": "yyyy-MM-dd",
                    format: "dd-MM-yyyy",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethod()
                    },
                  },
                  model: {
                    value: _vm.dateStartEnd,
                    callback: function ($$v) {
                      _vm.dateStartEnd = $$v
                    },
                    expression: "dateStartEnd",
                  },
                }),
                _c(
                  "el-input",
                  {
                    staticClass: "button-left-class",
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "Nhập nội dung tìm kiếm",
                      clearable: "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.searchHeaderMethod()
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchHeaderMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearch.sendContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "sendContent", $$v)
                      },
                      expression: "dataSearch.sendContent",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: {
                        click: function ($event) {
                          return _vm.searchHeaderMethod()
                        },
                      },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "button-click row-data" },
          [
            _c(
              "el-button",
              {
                staticClass: "button-over",
                attrs: { type: "success", loading: _vm.loaddingButton },
                on: {
                  click: function ($event) {
                    return _vm.approveSchoolUnread()
                  },
                },
              },
              [_vm._v("Duyệt đã đọc")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.notificationlist,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                "cell-style": _vm.tableRowStyle,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "50", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Thời gian", width: "150", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDateTime")(scope.row.createdDate)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sendTitle",
                  label: "Tiêu đề",
                  width: "300",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sendContent",
                  label: "Nội dung",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "numberFile",
                  label: "Số file",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleViewDetail(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("Xem")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paging-click row-data" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.pageNumber,
              "page-sizes": _vm.$pageListDefaultNew,
              "page-size": _vm.maxPageItem,
              layout: _vm.$pageLayoutDefault,
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("view-content", {
        ref: "ViewContent",
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseShowMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }