var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thông báo hệ thống",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "2vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "hr" }, [_c("hr")]),
      _c("div", { staticClass: "table-content row-data" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", { staticStyle: { "font-size": "18px" } }, [
            _vm._v("Tiêu đề :"),
          ]),
          _vm._v("\n      " + _vm._s(this.viewContent.sendTitle) + "\n    "),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v("Nội dung :"),
            ]),
            _c(
              "el-input",
              {
                attrs: { type: "textarea", rows: 10 },
                model: {
                  value: this.viewContent.sendContent,
                  callback: function ($$v) {
                    _vm.$set(this.viewContent, "sendContent", $$v)
                  },
                  expression: "this.viewContent.sendContent",
                },
              },
              [_vm._v(_vm._s(this.viewContent.sendContent))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "file" },
          [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v("File đính kèm :"),
            ]),
            _c("el-upload", {
              ref: "upload",
              staticClass: "upload-demo",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                action: "",
                multiple: "",
                "auto-upload": false,
                limit: 5,
                "file-list": _vm.viewContent.urlFileAppSendList,
                "on-preview": _vm.handleFileRequest,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }